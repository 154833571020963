import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

import { SoftUIControllerProvider } from "context";

import { configureStore } from "@reduxjs/toolkit";
import RootReducer from "./reducer/index.js";
import { Provider } from "react-redux";
import socketMiddleware from "sockets/SocketMiddleware.js";


const store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(socketMiddleware),
});

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </SoftUIControllerProvider>
  </BrowserRouter>
);
