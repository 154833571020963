import { apiConnector } from "services/Connector";
import { DeleteAccount } from "services/APIS";
const { DELETE_ACCOUNT } = DeleteAccount;

export async function deleteAccount(data) {
  try {
    const response = await apiConnector("DELETE", DELETE_ACCOUNT, data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}