import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    topupRequests: []
}

const topupSlice = createSlice({
    name: "topup",
    initialState: initialState,
    reducers: {
        setLoading(state, value) {
            state.loading = value.payload
        },
        setTopupRequests(state, value) {
            state.topupRequests = value.payload
        },
        removeTopupRequest(state, action) {
            state.topupRequests = state.topupRequests.filter(request => request.payment_image_id !== action.payload);
        },
    }
});

export const { setLoading, setTopupRequests, removeTopupRequest } = topupSlice.actions;
export default topupSlice.reducer;