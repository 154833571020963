import React, { useEffect, useState } from 'react';
import SoftBox from 'components/SoftBox';
import DataTable from 'react-data-table-component';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftTypography from 'components/SoftTypography';
import { Card } from 'reactstrap';
import { columns } from './DataTableColumns';
import { LoadingSpinner } from 'examples/LoadingSpinner/LoadingSpinner';
import { Box, Grid, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { acknowledgeTopupRequest, fetchTopupRequest } from '../../services/operations/TopupApi';

const columnNames = [
    {
        fieldName: "NORMAL",
        name: "User",
        selector: (row) => `${row.user.name} ${row.user.last_name}`,
    },
    {
        fieldName: "SCREENSHOT",
        name: "Screenshot",
        selector: (row) => row.image_url,
    },
    {
        fieldName: "AMOUNT",
        name: "Amount",
    },
    {
        fieldName: "ACTION",
        name: "Action",
    },
];

const Topup = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [open, setOpen] = useState(false);
    const [amounts, setAmounts] = useState({});
    const { topupRequests, loadingRequests } = useSelector((state) => ({
        topupRequests: state.topup.topupRequests,
        loadingRequests: state.topup.loading
    }));
    // eslint-disable-next-line
    const [actionLoading, setActionLoading] = useState(null);

    useEffect(() => {
        setLoading(loadingRequests);
    }, [loadingRequests]);

    useEffect(() => {
        dispatch(fetchTopupRequest());
    }, [dispatch]);

    const handleAmountChange = (requestId, value) => {
        setAmounts((prev) => ({
            ...prev,
            [requestId]: value ? parseFloat(value) : ""
        }));
    };

    const handleAction = async (request, status) => {
        const amount = amounts[request.payment_image_id];
        const data = {
            user_id: request.user_id,
            payment_image_id: request.payment_image_id,
            amount: amount ? amount : 0,
            status: status,
        };
        dispatch(acknowledgeTopupRequest(data));
    };

    const handleImageClick = (url) => {
        setImagePreview(url);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setImagePreview(null);
    };

    const handleBackdropClick = (event) => {
        if (event.target === event.currentTarget) {
            handleClose();
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox sx={{ height: '80vh' }} py={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SoftBox shadow="none" mb={3}>
                            <Card sx={{ boxShadow: 'none' }}>
                                {/* <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                    <SoftTypography variant="h6">Top-Up Requests</SoftTypography>
                                </SoftBox> */}
                                <SoftBox
                                    sx={{
                                        "& .MuiTableContainer-root": { height: '60vh' },
                                        "& .MuiTableRow-root:not(:last-child)": {
                                            "& td": {
                                                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                    `${borderWidth[1]} solid ${borderColor}`,
                                            },
                                        },
                                    }}
                                >
                                    {loading ? (
                                        <SoftBox sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <LoadingSpinner />
                                        </SoftBox>
                                    ) : (
                                        <DataTable
                                            // title={"Topup Lists"}
                                            data={topupRequests}
                                            columns={columns(columnNames, handleImageClick, handleAction, actionLoading, handleAmountChange, amounts)}
                                            highlightOnHover
                                            progressComponent={<LoadingSpinner />}
                                            pointerOnHover
                                            progressPending={loading}
                                            striped
                                            pagination
                                        />
                                    )}
                                </SoftBox>
                            </Card>
                        </SoftBox>
                    </Grid>
                </Grid>
            </SoftBox>

            {/* Modal for Image Preview */}
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
                    onClick={handleBackdropClick}
                >
                    <img src={imagePreview} alt="Preview" style={{ maxWidth: '90%', maxHeight: '90%' }} />
                </Box>
            </Modal>
        </DashboardLayout>
    );
};

export default Topup;
