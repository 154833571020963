import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import SoftBox from 'components/SoftBox';
import { getRides } from 'services/operations/RidesAPI';
import { columns } from "./DataTableColumns";
import { LoadingSpinner } from 'examples/LoadingSpinner/LoadingSpinner';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

const columnNames = [
    {
        name: 'Participants',
        fieldName: 'PARTICIPANTS',
    },
    {
        name: 'Origin',
        value: 'ride_request.source_name',
        fieldName: 'DESTINATION',
        selector: (row) => row?.ride_request?.source_name,

    },
    {
        name: 'Destination',
        value: 'ride_request.destination_name',
        fieldName: 'DESTINATION',
        selector: (row) => row?.ride_request?.destination_name,
    },
    {
        name: 'Distance',
        value: 'ride_request.distance',
        fieldName: 'DISTANCE',
        selector: (row) => row?.ride_request?.distance,
    },
    {
        name: 'Duration',
        fieldName: 'DURATION',
    },
    {
        name: 'Amount',
        value: 'ride_request.amount',
        fieldName: 'AMOUNT',
        selector: (row) => row?.ride_request?.amount,
    },
    {
        name: 'Status',
        fieldName: 'STATUS',
    },
    {
        name: 'Created at',
        fieldName: 'CREATEDAT',
    },
];

const Rides = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const searchFilter = useSelector((state) => state.search.search);
    const { Rides, Loading } = useSelector((state) => ({
        Rides: state.rides.rides,
        Loading: state.rides.loading,
    }));

    useEffect(() => {
        setLoading(Loading);
    }, [Loading]);

    useEffect(() => {
        dispatch(getRides());
    }, [dispatch]);

    const filteredUsers = Array.isArray(Rides) ? Rides.filter(user => {
        const source_name = `${user?.ride_request?.source_name}`.toLowerCase();
        const destination_name = (user?.ride_request?.destination_name).toLowerCase();
        const status = (user?.status).toLowerCase();
        const searchTerm = (searchFilter ?? '').toLowerCase();
        return source_name.includes(searchTerm) || destination_name.includes(searchTerm) || status.includes(searchTerm);
    }) : [];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox mt={5} mb={5}>
                <DataTable
                    // title={"Ride Lists"}
                    data={filteredUsers.length ? filteredUsers : []}
                    columns={columns(columnNames)}
                    highlightOnHover
                    progressComponent={<LoadingSpinner />}
                    // progressPending={loading}
                    progressPending={false}
                    striped
                    pagination
                />               
            </SoftBox>
        </DashboardLayout>
    );
};

export default Rides;
