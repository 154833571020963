import { createSlice } from "@reduxjs/toolkit";

const intialState = {
    resetpasswordData:{},
    data: {},
    loading:false,
};

const forgetpasswordslice = createSlice({
    name: "auth",
    initialState: intialState,
    reducers: {
        setForgotPasswordData(state, value) {
            state.data = value.payload
        },
        setLoading(state, value) {
            state.loading = value.payload
        },
        setResetPasswordData(state,value){
            state.resetpasswordData = value.payload
        }
    }
});

export const { setForgotPasswordData, setLoading,setResetPasswordData } = forgetpasswordslice.actions;
export default forgetpasswordslice.reducer;