import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "../slices/AuthSlice";
import dashboardReducer from "../slices/DashboardSlice";
import userReducer from "../slices/UserSlice";
import driverReducer from "../slices/DriverSlice";
import RideSlice from "slices/RideSlice";
import ReportedRidesSlice from "slices/ReportedRidesSlice";
import ReportedChats from "slices/ReportedChats";
import SearchSlice from "slices/SearchSlice";
import SocketReducer from "slices/SocketSlice";
import ForgetPassword from "slices/ForgetPassword";
import Topup from "slices/TopupSlice";
import Country from "slices/CountrySlice";

const RootReducer = combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer,
    user: userReducer,
    driver: driverReducer,
    reportedRides: ReportedRidesSlice,
    chat: ReportedChats,
    rides: RideSlice,
    search: SearchSlice,
    socket: SocketReducer,
    ForgetPassword,
    topup: Topup,
    country: Country
});

export default RootReducer; 