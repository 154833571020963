import { setForgotPasswordData } from "slices/ForgetPassword";
import { AuthEndpoints } from "../APIS";
import { toast,Bounce } from "react-toastify";
import { setLoading } from "slices/ForgetPassword";
import { apiConnector } from "services/Connector";
import { setResetPasswordData } from "slices/ForgetPassword";

const { VERIFY_OTP, RESETPASSWORD_API, FORGOTPASSWORD_API } = AuthEndpoints;

export function forgotpassword(data) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConnector("POST", FORGOTPASSWORD_API, data);
      dispatch(setLoading(false));
      return response.data;
      // dispatch(setForgotPasswordData(response.data));
    //   console.log("response.data: ", response.data);
     
    } catch (error) {
      dispatch(setLoading(false));
      return error.response.data;
    }
  };
}

export function verifyOTP(data) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConnector("POST", VERIFY_OTP, data);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
        dispatch(setLoading(false));
        return error.response.data;
    }
  };
}

export function resetpassword(data) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConnector("POST", RESETPASSWORD_API, data);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
        console.log('error: from loginnnnn', error);
        toast.error("Internal Server Error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        dispatch(setLoading(false));
        return false;
    }
  };
}
