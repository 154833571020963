import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Import toast for notifications
import CoverLayout from "../components/CoverLayout";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import img from "assets/images/image.png";
import SoftInput from "components/SoftInput";
import {
  forgotpassword,
  verifyOTP,
  resetpassword,
} from "../../../services/operations/ForgetPassword";
import CircularProgress from "@mui/material/CircularProgress";
const Forgotpassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isOTPVerified, setIsOtpVerified] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    OTP: "",
    token: "",
  });
  const [otpArray, setOtpArray] = useState(["", "", "", ""]); // State for OTP digits
  const [errors, setErrors] = useState({}); // State for validation errors

  // Fetching data and loading state from Redux store
  const {  loading } = useSelector((state) => ({
    loading: state.ForgetPassword?.loading,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // Clear validation error when user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleOTPChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtpArray = [...otpArray];
      newOtpArray[index] = value;

      setOtpArray(newOtpArray);
      setFormData((prevState) => ({
        ...prevState,
        OTP: newOtpArray.join(""),
      }));

      // Clear validation error when user starts typing
      setErrors((prevErrors) => ({
        ...prevErrors,
        OTP: "",
      }));

      // Move focus to next input if digit is entered
      if (value && index < 3) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleOTPKeyDown = (e, index) => {
    if (e.key === "Backspace" && otpArray[index] === "" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formValid = true;
    const newErrors = {};
    console.log("verifying form", formValid);

    if (!isOTPSent) {
      // Validate email for OTP sending
      if (!formData.email.trim()) {
        newErrors.email = "Email is required";
        formValid = false;
        console.log("formValid:email ", formValid);
      }
    }
    // Validate OTP and password for reset
    else {
      if(!isOTPVerified)
      if (!formData.OTP.trim() || formData.OTP.length !== 4) {
        newErrors.OTP = "Please enter a valid 4-digit OTP";
        formValid = false;
        console.log("formValid:OTP ", formValid);
      }
    }
    if (isOTPVerified)
      if (!formData.password.trim()) {
        newErrors.password = "Password is required";
        formValid = false;
        console.log("formValid: Passw", formValid);
      }

    setErrors(newErrors);
    console.log("verifying form", formValid);
    let response;
    let token;

    if (formValid) {
      try {
        if (!isOTPSent) {
          // Send OTP logic
          response = await dispatch(forgotpassword({ email: formData.email }));
          console.log("response: ", response);
          if (response.success) {
            setIsOTPSent(true);
            toast.success("OTP sent to your email");
          } else {
            if (response.success === false) toast.error("User Not Found");
            setFormData({ email: "" });
          }
        } else if (!isOTPVerified) {
          // Verify OTP logic
          token = await dispatch(verifyOTP({ otp: formData.OTP }));
          if (token.success===true) {
            setFormData({ token: token.data });
            setIsOtpVerified(true);
            toast.success("OTP verified successfully");
          } else {
            if (token.success === false) toast.error("Invalid OTP");
            setFormData({ OTP:null });
          }
        } else if (isOTPVerified) {
          // Reset Password logic

          console.log("token: ", formData.token);
          const resetSuccess = await dispatch(
            resetpassword({ token: formData.token, password: formData.password })
          );
          console.log("resetSuccess: ", resetSuccess);

          if (resetSuccess) {
            toast.success("Password reset successfully");
            navigate("/authentication/sign-in"); // Redirect after successful reset
          } else {
            navigate("/authentication/sign-in");
          }
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred");
      }
    }
  };

  return (
    <CoverLayout title="Forgot Password" description="Enter your email to verify" image={img}>
      <SoftBox component="form" role="form" onSubmit={handleSubmit}>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="email"
            name="email"
            placeholder="Email"
            onChange={handleChange}
            value={formData.email}
            error={!!errors.email}
            helperText={errors.email}
            disabled={isOTPVerified || loading}
          />
        </SoftBox>

        {isOTPSent && (
          <>
            {isOTPVerified ? (
              <>
                {/* Password input after OTP verification */}
                <SoftBox mb={2}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      New Password
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="password"
                    name="password"
                    placeholder="New Password"
                    onChange={handleChange}
                    value={formData.password}
                    error={!!errors.password}
                    helperText={errors.password}
                    disabled={loading}
                  />
                </SoftBox>
              </>
            ) : (
              <>
                {/* OTP input before password reset */}
                <SoftBox mb={2}>
                  <SoftBox mb={1} ml={0.5}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      OTP
                    </SoftTypography>
                  </SoftBox>
                  <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                    {otpArray.map((digit, index) => (
                      <input
                        key={index}
                        type="tel"
                        id={`otp-input-${index}`}
                        name={`otp-${index}`}
                        value={digit}
                        maxLength="1"
                        onChange={(e) => handleOTPChange(e, index)}
                        onKeyDown={(e) => handleOTPKeyDown(e, index)}
                        style={{
                          width: "80px",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: errors.OTP ? "1px solid red" : "1px solid #ccc",
                          borderRadius: "4px",
                          textAlign: "center",
                          fontSize: "18px",
                          outline: "none",
                        }}
                        disabled={loading}
                      />
                    ))}
                  </div>
                  {errors.OTP && (
                    <SoftTypography color="error" variant="caption" fontWeight="regular">
                      {errors.OTP}
                    </SoftTypography>
                  )}
                </SoftBox>
              </>
            )}
          </>
        )}

        <SoftBox mt={4} mb={1}>
          {!isOTPSent ? (
            <SoftButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
              {loading ? <CircularProgress size={20} /> : "Send OTP"}
            </SoftButton>
          ) : (
            <>
              {!isOTPVerified ? (
                <SoftButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={20} /> : "Verify OTP"}
                </SoftButton>
              ) : (
                <SoftButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={20} /> : "Reset Password"}
                </SoftButton>
              )}
            </>
          )}
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
};

export default Forgotpassword;
