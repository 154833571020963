import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import "./app.css";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { toast, Bounce, ToastContainer } from "react-toastify";

import SoftBox from "components/SoftBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import routes from "routes";
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brand from "assets/images/logo-ct.png";
import SignIn from "layouts/authentication/sign-in";
import { connectSocket, getSocket, disconnectSocket } from "./sockets/Socket";
import { sosData } from "./slices/SocketSlice";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  const auth = localStorage.getItem("auth");
  const token = auth ? auth : null;
  const navigate = useNavigate();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const reduxDispatch = useDispatch();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Socket initialization and SOS event handling
  useEffect(() => {
    if (token) {
      // console.log("token", token);
      const socket = getSocket(token);

      // Emit the initial SOS request
      socket.emit("fetchSOS", {
        message: "SOS request from client",
      });

      // Clean up existing listeners before setting up new ones
      socket.off("getSOS");
      socket.off("sendSOS");

      // Listen for the SOS data response
      socket.on("getSOS", (data) => {
        // console.log("Received data:", data);
        reduxDispatch(sosData(data));
      });

      // Handle receiving a sendSOS event
      socket.on("sendSOS", (data) => {
        // console.log("Received SOS:", data.rideData.user.name);
        toast.error(`SOS from ${data.rideData.user.name}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        // console.log("Received sendSOS data:", data);
        // Re-emit the SOS request after receiving an SOS message
        socket.emit("fetchSOS", {
          message: "SOS request from client",
        });
      });

      // Cleanup function to disconnect the socket and remove listeners when the component unmounts or token changes
      return () => {
        if (socket) {
          socket.off("getSOS");
          socket.off("sendSOS");
          socket.disconnect();
          // console.log("Socket disconnected");
        }
      };
    }
  }, [token, reduxDispatch]);

  const flattenRoutes = (routes) => {
    return routes.reduce((flat, route) => {
      return flat.concat(route.collapse ? flattenRoutes(route.collapse) : route);
    }, []);
  };

  const getRoutes = (allRoutes) => {
    const flatRoutes = flattenRoutes(allRoutes);

    return flatRoutes.map((route) => {
      if (route?.name === "forgot-password" || route?.name === "delete-account") {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      if (token !== null) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      } else {
        return <Route path="*" element={<SignIn />} key={route.key} />;
      }
    });
  };

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  const handleClick = () => {
    // console.log("Button clicked");
    navigate("/emergency");
  };

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ToastContainer />
      <ThemeProvider theme={themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="OkadaMotto"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {token && layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="Okada Motto"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
        </Routes>
      </ThemeProvider>
      <ToastContainer onClick={handleClick} />
    </>
  );
}
