import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { LoadingSpinner } from "examples/LoadingSpinner/LoadingSpinner";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid'; // Ensure you import uuidv4

const columnsNames = [
  {
    name: (
      <SoftTypography sx={{ fontSize: '0.9rem',fontWeight:'bold'}}>
        User
      </SoftTypography>
    ),
    value: 'profileImg',
    flex: 1,
    minWidth: '250px',
    cell: (row) => (
      <SoftBox
        key={uuidv4()} // Unique key for each cell
        component="td"
        p={1}
      >
        <SoftBox display="flex" alignItems="flex-start" flexWrap="wrap" sx={{ width: '100%' }}>
          <SoftBox mr={2}> {/* Adding margin to separate the avatar and text */}
            <SoftAvatar
              src={`${row.user.profileImg}`}
              name={`${row.user.name} ${row.user.lastName}`}
              variant="rounded"
              size="sm"
            />
          </SoftBox>
          <SoftBox sx={{ minWidth: 0, flexGrow: 1, flexBasis: 'auto' }}>
            <SoftTypography 
              variant="button" 
              fontWeight="medium" 
              sx={{ width: '100%', wordWrap: 'break-word' }} // Allows wrapping if the text exceeds the width
            >
              {row.user.name} {row.user.lastName} 
            </SoftTypography>
            <SoftTypography 
              variant="caption" 
              sx={{ width: '100%', wordWrap: 'break-word', display: 'block' }}
            >
              <a 
                href={`mailto:${row.user.email}`} 
                style={{ 
                  textDecoration: 'none', 
                  color: 'inherit', 
                  cursor: 'pointer', 
                  transition: 'color 0.3s' 
                }}
                onMouseEnter={(e) => e.target.style.color = 'blue'} 
                onMouseLeave={(e) => e.target.style.color = 'inherit'}
              >
                {row.user.email}
              </a>
            </SoftTypography>
            <SoftTypography 
              variant="caption" 
              fontWeight="medium" 
              sx={{ width: '100%', wordWrap: 'break-word', display: 'block' }}
            >
              Ph: <a 
                    href={`tel:${row.user.phone}`} 
                    style={{ 
                      textDecoration: 'none', 
                      color: 'inherit', 
                      cursor: 'pointer', 
                      transition: 'color 0.3s' 
                    }}
                    onMouseEnter={(e) => e.target.style.color = 'blue'} 
                    onMouseLeave={(e) => e.target.style.color = 'inherit'}
                  >
                    {row.user.phone}
                  </a>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    ),
  },
  {
    name: (
      <SoftTypography sx={{ fontSize: '0.9rem',fontWeight:'bold' }}>
        Driver
      </SoftTypography>
    ),
    value: 'profileImg',
    flex: 1,
    minWidth: '250px',
    cell: (row) => (
      <SoftBox
        key={uuidv4()} // Unique key for each cell
        component="td"
        p={1}
      >
        <SoftBox display="flex" alignItems="flex-start" flexWrap="wrap" sx={{ width: '100%' }}>
          <SoftBox mr={2}> {/* Adding margin to separate the avatar and text */}
            <SoftAvatar
              src={`${row.driver.user.profileImg}`} 
              name={`${row.driver.user.name}`} 
              variant="rounded"
              size="sm"
            />
          </SoftBox>
          <SoftBox sx={{ minWidth: 0, flexGrow: 1, flexBasis: 'auto' }}>
            <SoftTypography 
              variant="button" 
              fontWeight="medium" 
              sx={{ width: '100%', wordWrap: 'break-word' }} 
            >
              {row.driver.user.name}
            </SoftTypography>
            <SoftTypography 
              variant="caption" 
              sx={{ width: '100%', wordWrap: 'break-word', display: 'block' }}
            >
              <a 
                href={`mailto:${row.driver.user.email}`} 
                style={{ 
                  textDecoration: 'none', 
                  color: 'inherit', 
                  cursor: 'pointer', 
                  transition: 'color 0.3s' 
                }}
                onMouseEnter={(e) => e.target.style.color = 'blue'} 
                onMouseLeave={(e) => e.target.style.color = 'inherit'}
              >
                {row.driver.user.email}
              </a>
            </SoftTypography>
            <SoftTypography 
              variant="caption" 
              fontWeight="medium" 
              sx={{ width: '100%', wordWrap: 'break-word', display: 'block' }}
            >
              Ph: <a 
                    href={`tel:${row.driver.user.phone}`} 
                    style={{ 
                      textDecoration: 'none', 
                      color: 'inherit', 
                      cursor: 'pointer', 
                      transition: 'color 0.3s' 
                    }}
                    onMouseEnter={(e) => e.target.style.color = 'blue'} 
                    onMouseLeave={(e) => e.target.style.color = 'inherit'}
                  >
                    {row.driver.user.phone}
                  </a>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    ),
  },
  {
    name: (
      <SoftTypography sx={{ fontSize: '0.9rem',fontWeight:'bold' }}>
        Source
      </SoftTypography>
    ),
    Value: 'row.ride_request.source_name',
    flex: 1,
    minWidth: '200px',
    cell: (row) => (
      <SoftBox>
        <SoftTypography variant="caption" fontWeight="medium" sx={{ width: '100%', wordWrap: 'break-word' }}>
          {row.ride_request.source_name}
        </SoftTypography>
      </SoftBox>
    )
  },
  {
    name: (
      <SoftTypography sx={{ fontSize: '0.9rem',fontWeight:'bold' }}>
        Destination
      </SoftTypography>
    ),
    Value: 'row.ride_request.destination_name',
    minWidth: '200px',
    flex: 1,
    cell: (row) => (
      <SoftBox>
        <SoftTypography variant="caption" fontWeight="medium" sx={{ width: '100%', wordWrap: 'break-word' }}>
          {row.ride_request.destination_name}
        </SoftTypography>
      </SoftBox>
    )  
  },
  {
    name: (
      <SoftTypography sx={{ fontSize: '0.9rem',fontWeight:'bold' }}>
        Status
      </SoftTypography>
    ),
    Value: 'status',
    selector: (row) => row.status
  },
  {
    name: (
      <SoftTypography sx={{ fontSize: '0.9rem',fontWeight:'bold' }}>
        User Location
      </SoftTypography>
    ),
    value: 'location',
    flex: 1,
    minWidth: '200px',
    cell: (row) => {
      const { coordinates } = row.user.location; 
      const [latitude, longitude] = coordinates;

      const handleClick = () => {
        const mapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
        window.open(mapsUrl, '_blank'); // Opens Google Maps in a new tab
      };

      return (
        <SoftButton
          onClick={handleClick}
          variant="contained"
          color="warning"
          size="small"
        >
          View on Map
        </SoftButton>
      );
    },
  },
];

const Emergency = () => {
  const [loading, setLoading] = useState();

  
  const sosData=useSelector((state)=>state.socket.notifications)
  useEffect(()=>{
    // console.log(sosData)
  })
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={5} mb={5}>
      <DataTable
        // title={"Ride List"}
        data={sosData?.emergencyData||[]}
        columns={columnsNames}
        highlightOnHover
        pointerOnHover
        progressPending={loading}
        progressComponent={<LoadingSpinner />}
        striped
        // onRowClicked={handleRowClick}
        pagination
       />
      </SoftBox>
    </DashboardLayout>
  );
};

export default Emergency;
