import { createSlice } from "@reduxjs/toolkit";

const initialState = { country: localStorage.getItem("country") };

const countrySlice = createSlice({
    name: "country",
    initialState: initialState,
    reducers: {
        setCountry(state, value) {
            state.country = value.payload
        },
    }
});

export const { setCountry } = countrySlice.actions;
export default countrySlice.reducer;