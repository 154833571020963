import { useState } from "react";
import axios, { CancelToken } from "axios";
import { Link, useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import img from "assets/images/image.png"
import { useDispatch } from "react-redux";
import { login } from "services/operations/AuthApi";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rememberMe, setRememberMe] = useState(true);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // Clear validation error when user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formValid = true;
    const newErrors = {};

    // Check if email is empty
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      formValid = false;
    }

    // Check if password is empty
    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
      formValid = false;
    }

    // Update errors state with new validation errors
    setErrors(newErrors);

    if (formValid) {
      try {
        if (cancelTokenSource) {
          cancelTokenSource.cancel("Canceling previous login request");
        }

        const newCancelTokenSource = CancelToken.source();
        setCancelTokenSource(newCancelTokenSource);

        const success = await dispatch(login(formData, newCancelTokenSource));
        if (success) {
          console.log("success: ", success);
          navigate("/dashboard");
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Login error:", error.response?.data);
        }
      }
    }
  };

  return (
    <CoverLayout
      title="Welcome back"
      description="Enter your email and password to sign in"
      image={img}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="email"
            name="email"
            placeholder="Email"
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            name="password"
            placeholder="Password"
            onChange={handleChange}
            error={!!errors.password}
            helperText={errors.password}
          />
        </SoftBox>
        <SoftTypography
          component={Link}
          to="/forgotpassword"
          variant="button"
          color="red"
          fontWeight="bold"
          textGradient
        >
          Forgot Password ?
        </SoftTypography>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" fullWidth onClick={handleSubmit} type="submit">
            Sign In
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
};

export default SignIn;
