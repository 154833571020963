import { io } from 'socket.io-client';
import { jwtDecode } from "jwt-decode";

let socket;
const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const connectSocket = (token) => {

    if (!token) { return null; }

    if (!socket) {
        socket = io(BASE_URL, {
            transports: ['websocket'],
            auth: { token },
            path: '/socket',
            autoConnect: true,
            reconnection: true,
        });

        socket.on('connect_error', (error) => {
            console.error('Connection Error:', error.message);
        });

        socket.on('reconnect_attempt', (attempt) => {
            console.log(`Reconnect Attempt #${attempt}`);
        });

        socket.on('reconnect_failed', () => {
            console.error('Reconnect Failed');
        });

        socket.on('error', (error) => {
            console.error('Socket Error:', error);
        });


        socket.on('connect', () => {
            console.log('Socket connected successfully!');
            const decoded = jwtDecode(token);
            const data = { "user_id": decoded.user_id, "role": "ADMIN" };
            socket.emit("setup", data, (cb) => {
                console.log('Acknowledgment received:', cb);
            });
        });
    }

    return socket;
};

export const getSocket = (token) => {
    if (!socket) {
        socket = connectSocket(token);
    } else {
        socket.auth.token = token;
        if (!socket.connected) {
            socket.connect();
        }
    }
    return socket;
};

export const disconnectSocket = () => {
    if (socket) {
        socket.disconnect();
        socket = null;
    }
};

export default connectSocket;
