import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

import breakpoints from "assets/theme/base/breakpoints";
import curved0 from "assets/images/curved-images/curved0.jpg";
import { Button, Dialog, DialogContent } from "@mui/material";

const BASE_URL = process.env.REACT_APP_BASE_URL;

function Header({ Driver, selectedTab, setSelectedTab }) {
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const handleClickOpen = (image) => {
        setSelectedImage(image);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setSelectedImage(null);
    };
    useEffect(() => {
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        window.addEventListener("resize", handleTabsOrientation);
        handleTabsOrientation();

        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const handleSetTabValue = (event, newValue) => setSelectedTab(newValue);

    return (
        <SoftBox position="relative">
            <SoftBox
                display="flex"
                alignItems="top"
                position="relative"
                minHeight="7.75rem"
                borderRadius="xl"
                sx={{
                    backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.info.main, 0.6),
                            rgba(gradients.info.state, 0.6)
                        )}, url(${curved0})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                    padding: "10px"
                }}
            >
                <SoftTypography variant="h3" fontWeight="bold" sx={{ padding: "10px" }}>
                    Driver Details
                </SoftTypography>
            </SoftBox>
            <Card
                sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <Grid container spacing={3} alignItems="center">
                    <Grid item onClick={() => handleClickOpen(Driver?.profileImg)}>
                        <SoftAvatar
                            src={Driver?.profileImg ? `${Driver?.profileImg}` : 'https://avatar.iran.liara.run/public/29'}
                            alt="profile-image"
                            variant="rounded"
                            size="xl"
                            shadow="sm"
                        />
                    </Grid>
                    <Grid item>
                        <SoftBox height="100%" mt={0.5} lineHeight={1}>
                            <SoftTypography variant="h5" fontWeight="medium">
                                {`${Driver?.name} ${Driver?.last_name}`}
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
                        <AppBar position="static">
                            <Tabs
                                orientation={tabsOrientation}
                                value={selectedTab}
                                onChange={handleSetTabValue}
                                sx={{ background: "transparent", padding: '10px' }}
                            >
                                <Tab label="Rides" icon={<Cube />} />
                                <Tab label="Details" icon={<Document />} />
                                {/* <Tab label="Settings" icon={<Settings />} /> */}
                            </Tabs>
                        </AppBar>
                    </Grid>
                    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                            <DialogContent>
                                {selectedImage && (
                                    <img src={selectedImage} alt={"image"} style={{ width: '100%', }} />
                                )}
                            </DialogContent>
                        </Dialog>
                </Grid>
            </Card>
        </SoftBox>
    );
}

Header.propTypes = {
    Driver: PropTypes.object.isRequired,
    selectedTab: PropTypes.number.isRequired,
    setSelectedTab: PropTypes.func.isRequired,
};

export default Header;
