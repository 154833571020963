import { createSlice } from "@reduxjs/toolkit";

const intialState = {
    dashboardData: null,
    loading: false,
    dashboardGraph:null,
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: intialState,
    reducers: {
        setLoading(state, value) {
            state.loading = value.payload
        },
        setDashboardData(state, value) {
            state.dashboardData = value.payload
        },
        setDashboardGraph(state,value){
            state.dashboardGraph = value.payload
        }
    }
});


export const { setLoading, setDashboardData,setDashboardGraph } = dashboardSlice.actions;
export default dashboardSlice.reducer;