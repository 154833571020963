import { useDebugValue, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useDispatch, useSelector } from "react-redux";
import { verifyDriver } from "services/operations/DriverApi";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";


// eslint-disable-next-line react/prop-types
function PlatformSettings({ user_id, name, email, mobile, bike, wallet }) {
    const dispatch = useDispatch();
    const [verified, setVerified] = useState(false);
    const [answersPost, setAnswersPost] = useState(false);
    const [mentionsMe, setMentionsMe] = useState(true);
    const [newLaunches, setNewLaunches] = useState(false);
    const [productUpdate, setProductUpdate] = useState(true);
    const [newsletter, setNewsletter] = useState(true);

    const { Driver } = useSelector((state) => ({
        Driver: state.driver.driverDetails,
    }));


    useEffect(() => {
        if (Driver && Driver.driver) {
            setVerified(Driver.driver.verified);
        }
    }, [Driver]);

    const changeHandler = () => {
        setVerified(!verified);
        dispatch(verifyDriver({ user_id, status: !verified }));
        // console.log('verified: ', verified);
    }


    return (
        <Card>
            <SoftBox px={2} mt={3} mb={3} pb={8} lineHeight={1.25}>

            <ProfileInfoCard
                            title="profile information"
                            //description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
                            info={{
                                fullName: name,
                                mobile: mobile,
                                email: email,
                                bike: bike,
                                wallet: wallet,
                                //location: Driver?.location,
                            }}

                            social={[
                                {
                                    link: "https://www.facebook.com/CreativeTim/",
                                    icon: <FacebookIcon />,
                                    color: "facebook",
                                },
                                {
                                    link: "https://twitter.com/creativetim",
                                    icon: <TwitterIcon />,
                                    color: "twitter",
                                },
                                {
                                    link: "https://www.instagram.com/creativetimofficial/",
                                    icon: <InstagramIcon />,
                                    color: "instagram",
                                },
                            ]}
                            action={{ route: "", tooltip: "Edit Profile" }}
                        />
                        <br></br>
                <SoftTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                    account
                </SoftTypography>

                <SoftBox display="flex" py={1} mb={0.25} mt={2}>
                    <SoftBox mt={0.25}>
                        <Switch checked={verified} onChange={changeHandler} />
                    </SoftBox>

                    <SoftBox width="80%" ml={2} >
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                            Verified
                        </SoftTypography>
                    </SoftBox>

                </SoftBox>

                {/* <SoftBox display="flex" py={1} mb={0.25}>

                    <SoftBox mt={0.25}>
                        <Switch checked={answersPost} onChange={() => setAnswersPost(!answersPost)} />
                    </SoftBox>

                    <SoftBox width="80%" ml={2}>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                            Block
                        </SoftTypography>
                    </SoftBox>

                </SoftBox> */}
                

               


                </SoftBox>

        

        </Card>
    );
}

PlatformSettings.propTypes = {
    user_id: PropTypes.shape({
        user_id: PropTypes.number.isRequired,
        name:PropTypes.string.isRequired,
        email:PropTypes.string.isRequired,
        mobile:PropTypes.number.isRequired,
        bike:PropTypes.string.isRequired,
        wallet:PropTypes.string.isRequired,
    }).isRequired,
};

export default PlatformSettings;
