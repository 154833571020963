import { createSlice } from "@reduxjs/toolkit";

const intialState = {
    reportedRidesChat:null,
    loading: false,
};

const reportedRideChats = createSlice({
    name: "Chats",
    initialState: intialState,
    reducers: {
        setLoading: (state,action) => {
            state.loading = action.payload;
            },
        setFailure: (state) => {
            state.loading = false;
            },
        setReportedRidesChat:(state,action)=>{
            state.loading=false;
            state.reportedRidesChat = action.payload;
        }
}
});

export const {setReportedRidesChat,setLoading,setFailure } = reportedRideChats.actions;
export default reportedRideChats.reducer;