import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import { getChats, replyChat } from "services/operations/ReportedChats";
import "./ChatView.css"; // Create and import a CSS file for custom styles
import SoftBox from "components/SoftBox";
import { Card, IconButton, InputAdornment } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {LoadingSpinner} from "examples/LoadingSpinner/LoadingSpinner";

const DEFAULT_AVATAR = "https://avatar.iran.liara.run/public/29";


const ChatView = ({ report }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.chat.reportedRidesChat);
  const loading = useSelector((state) => state.chat.loading);
  const [messages, setMessages] = useState([]);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const messagesEndRef = useRef(null);

  const getChatsCallback = useCallback(async () => {
    await dispatch(getChats(report.ride_id));
  }, [dispatch, report.ride_id]);

  useEffect(() => {
    if (report) {
      getChatsCallback();
    }
  }, [getChatsCallback, report]);

  useEffect(() => {
    if (data && data.reportData) {
      setMessages(data.reportData);
    }
  }, [data]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("ride_id", report.ride_id);
    formData.append("user_id", report.user_id);
    formData.append("description", description);
    if (image) {
      formData.append("report_image", image);
    }
    const newMessage = {
      report_id: Date.now(),
      user_id: 3,
      description,
      image_url: image ? URL.createObjectURL(image) : null,
      ride_id: report.ride_id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      is_admin: true,
      name: "Admin",
      last_name: "",
      profileImg: DEFAULT_AVATAR,
      email: "admin@example.com",
    };
    await dispatch(replyChat(formData));
    setMessages([...messages, newMessage]);
    setDescription("");
    setImage(null);
  };

  return (
    <Card style={{ boxShadow: "none", maxHeight: "calc(80vh - 100px)", overflowY: "auto" }}>
      <SoftBox className="chat-view-container">
        <SoftBox className="chat-header">
          <SoftBox mr={2}>
            <SoftAvatar
              src={report.profileImg ? `${report.profileImg}` : DEFAULT_AVATAR}
              alt={report.name}
              size="sm"
              variant="rounded"
            />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
              {report.name}
            </SoftTypography>
            <SoftTypography variant="caption" color="secondary">
              {report.email}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox p={3} className="messages-container">
          {loading ? (
            <LoadingSpinner />
          ) : (
            messages.map((message) => (
              <SoftBox
                key={message.report_id}
                className={`message ${message.is_admin ? "admin" : "user"}`}
              >
                <SoftTypography variant="body2">{message.description}</SoftTypography>
                {message.image_url && (
                  <img
                    src={
                      message.image_url.startsWith("blob:")
                        ? message.image_url
                        : `${message.image_url}`
                    }
                    alt="Message"
                    className="message-image"
                  />
                )}
              </SoftBox>
            ))
          )}
          <SoftBox ref={messagesEndRef}></SoftBox>
        </SoftBox>
        <form onSubmit={handleSubmit} className="message-form">
          <SoftBox className="input-container">
            <textarea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                e.target.style.height = "auto";
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
              placeholder="Type your message"
              className="message-input"
              required
            />
            <InputAdornment position="end">
              <IconButton
                component="label"
                sx={{
                  color: "#fff",
                  backgroundColor: "#FFE134",
                }}
              >
                <UploadFileIcon />
                <input type="file" onChange={(e) => setImage(e.target.files[0])} hidden />
              </IconButton>
            </InputAdornment>
          </SoftBox>
          <button type="submit" className="send-button">
            Send
          </button>
        </form>
      </SoftBox>
    </Card>
  );
};

ChatView.propTypes = {
  report: PropTypes.shape({
    report_id: PropTypes.number.isRequired,
    ride_id: PropTypes.number.isRequired,
    user_id: PropTypes.number.isRequired,
    profileImg: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image_url: PropTypes.string,
  }).isRequired,
};

export default ChatView;
