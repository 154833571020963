import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    drivers: [],
    driverDetails: [],
    loading: false,
}


const driverSlice = createSlice({
    name: "driver",
    initialState: initialState,
    reducers: {
        setDriver(state, value) {
            state.drivers = value.payload
        },
        setDriverDetail(state, value) {
            state.driverDetails = value.payload
        },
        setLoading(state, value) {
            state.loading = value.payload
        },
    }
});

export const { setDriver, setDriverDetail, setLoading } = driverSlice.actions;
export default driverSlice.reducer;