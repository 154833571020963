import { createSlice } from "@reduxjs/toolkit";

const initialState={
    search:null,
}
const searchSlice=createSlice({
    name:"search",
    initialState,
    reducers:{
        setSearch(state,value){
            state.search=value.payload
        },
        clearSearch(state){
            state.search=null
        }
    }
});
export const {setSearch,clearSearch}=searchSlice.actions;
export default searchSlice.reducer;