import { useMemo } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";

import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

function Table({ columns, rows }) {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const height=rows.length?'63vh':'auto'
  const renderColumns = rows.length?columns.map(({ name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize="0.9rem" // Changed font size here
        fontWeight={fontWeightBold}
        color="Black"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {name}
      </SoftBox>
    );
  }):(<></>)

  const renderRows = rows.length ? rows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align }) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
              <SoftBox mr={2}>
                <SoftAvatar src={row[name][0]} name={row[name][1]} variant="rounded" size="sm" />
              </SoftBox>
              <SoftTypography variant="button" fontWeight="medium" sx={{ width: "max-content" }}>
                {row[name][1]}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        );
      } else {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
              sx={{ display: "inline-block", width: "max-content" }}
            >
              {row[name]}
            </SoftTypography>
          </SoftBox>
        );
      }

      return template;
    })

    return (
      <TableRow key={rowKey} onClick={row.onClick} sx={{ cursor: "pointer" }}>
        {tableRow}
      </TableRow>
    );
  }) : (
    <SoftBox component="td" colSpan={columns.length} textAlign="center" p={2}>
      <SoftTypography fontWeight="medium" color="secondary">
        No records
      </SoftTypography>
    </SoftBox>
  );

  return useMemo(
    () => (
      <TableContainer sx={{ '&.MuiTableContainer-root': { height: `${height}` ,boxShadow:'none !important '} }}>
        <MuiTable>
          <SoftBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </SoftBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
}
// return useMemo(
  // () => (
    // <TableContainer sx={{ '&.MuiTableContainer-root': { height: `${height}` , boxShadow: 'none !important' } }}>
      {/* <MuiTable> */}
        {/* <SoftBox component="thead"> */}
          {/* <TableRow>{renderColumns}</TableRow> */}
        {/* </SoftBox> */}
        {/* <TableBody> */}
          {/* <SoftBox component="tbody" sx={{ overflowY: 'auto', height: '60vh' }}> */}
            {/* {renderRows} */}
          {/* </SoftBox> */}
        {/* </TableBody> */}
      {/* </MuiTable> */}
    {/* </TableContainer> */}
  // ),
  // [columns, rows]
// );

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
