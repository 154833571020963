const BASE_URL = process.env.REACT_APP_BASE_URL;
const DEFAULT_AVATAR = 'https://avatar.iran.liara.run/public/29';


export const columns = (columnNames, fetchUserDetails) => {
    return columnNames?.map((column) => {
        let col;
        switch (column?.FieldName) {

            case "IMAGE":
                return col = {
                    name: <span className='font-weight-bold fs-13' style={{
                        fontSize:'0.9rem'
                    }}>{column?.name}</span>,
                    selector: row => row[column.Value],
                    cell: row => {
                        const imageUrl = row[column.Value] ? `${row[column.Value]}` : DEFAULT_AVATAR;
                        const userId = row.user_id;
                        return (
                            <div style={{ textAlign: 'center' }} className="profile">
                                <img
                                    src={imageUrl}
                                    alt="Image"
                                    style={{ width: '50px', height: '50px', objectFit: 'cover', cursor: 'pointer' }}
                                    className="profile"
                                    // onClick={(() => fetchUserDetails(userId))}
                                />
                            </div>
                        );
                    },
                    wrap: true
                };

            case "NAME":{
                return col = {
                    name: <span className='font-weight-bold fs-13'style={{
                        fontSize:'0.9rem'
                    }}>{column?.name}</span>,
                    selector: row => row[column.Value],
                    cell: row => {
                        return row.name?(row?.name +' '+((row?.last_name===null)?(''):(row.last_name))):('N/A')
                        }
                }
            }
            case "NORMAL":
                return col = {
                    name: <span className='font-weight-bold fs-13 text-lg'style={{
                        fontSize:'0.9rem'
                    }}>{column?.name}</span>,
                    selector: row => {
                        return row[column.Value] !== undefined ? row[column.Value] : 'N/A';
                    },
                    wrap: true
                };

            default:
                return col = {
                    name: <span className='font-weight-bold fs-13'style={{
                        fontSize:'0.9rem'
                    }}>{column?.name}</span>,
                    selector: row => {
                        return ['CreatedDate', 'ModifiedDate'].includes(column?.Title) ? new Date(row[column?.Title]).toDateString() : row[column?.Title]
                    },
                    wrap: true
                };
        }
    })
}