import { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import { useSoftUIController, setMiniSidenav } from "context";
import { useDispatch } from "react-redux";
import { setCountry } from "slices/CountrySlice";

const countries = [
  { code: 'GHANA', name: 'Ghana', flag: 'https://flagcdn.com/gh.svg' },
  { code: 'LIBERIA', name: 'Liberia', flag: 'https://flagcdn.com/lr.svg' },
];


function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useSoftUIController();
  const [selectedCountry, setSelectedCountry] = useState(countries[0].code)
  const dispatch2 = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const { miniSidenav, transparentSidenav, } = controller;
  const closeSidenav = () => setMiniSidenav(dispatch, true);
  const collapseName = pathname.split("/").slice(1)[0];

  const handleChangeCountry = (e) => {
    localStorage.setItem("country", e.target.value);
    setSelectedCountry(e.target.value);
    dispatch2(setCountry(e.target.value));
  };

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location, selectedCountry]);

  useEffect(() => {
    const storedCountry = localStorage.getItem("country") ? localStorage.getItem("country") : countries[0].code;
    if (storedCountry) {
      setSelectedCountry(storedCountry);
      dispatch2(setCountry(storedCountry));
      localStorage.setItem("country", storedCountry)
    }
  }, []);

  const renderRoutes = routes
    .filter(route => route.showInNav !== false)
    .map(({ type, name, icon, title, noCollapse, key, route, href }) => {
      let returnValue;

      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              color={color}
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink to={route} key={key}>
            <SidenavCollapse
              color={color}
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <SoftTypography
            key={key}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </SoftTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} />;
      }

      return returnValue;
    });

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <SoftBox pt={3} pb={1} px={4} textAlign="center">

        <SoftBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SoftTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SoftTypography>
        </SoftBox>

        <SoftBox component={NavLink} to="/dashboard" display="flex" alignItems="center">
          {brand && <SoftBox component="img" src={brand} alt="Soft UI Logo" width="2rem" />}
          <SoftBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <SoftTypography component="h4" variant="button" fontWeight="large">
              {brandName}
            </SoftTypography>
          </SoftBox>
        </SoftBox>

        {/* <SoftBox mt={2}>
          <Select
            value={selectedCountry}
            onChange={(e) => handleChangeCountry(e)}
            displayEmpty
            renderValue={(value) => {
              const country = countries.find(c => c.code === value);
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {country && (
                    <>
                      <img
                        src={country.flag}
                        alt={country.name}
                        style={{
                          width: '24px',
                          height: '16px',
                          marginRight: '10px',
                        }}
                      />
                      <span>{country.name}</span>
                    </>
                  )}
                </div>
              );
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  display: 'flex',
                  flexDirection: 'column',
                },
              },
            }}
            sx={{ width: '50%', textAlign: 'center' }}
          >
            {countries.map((country) => (
              <MenuItem
                key={country.code}
                value={country.code}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 16px',
                }}
              >
                <img
                  src={country.flag}
                  alt={country.name}
                  style={{
                    width: '24px',
                    height: '16px',
                    marginRight: '10px',
                  }}
                />
                <span style={{ whiteSpace: 'nowrap' }}>{country.name}</span>
              </MenuItem>
            ))}
          </Select>
        </SoftBox> */}

      </SoftBox>
      <Divider />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
