import moment from "moment";
import React from 'react';
import { useNavigate } from "react-router-dom";
const DEFAULT_AVATAR = "https://avatar.iran.liara.run/public/29";

export const columns = (columnNames) => {
    const navigate = useNavigate();
    return columnNames?.map((column) => {
        let col;
        switch (column?.fieldName) {
            case "PARTICIPANTS":
              col = {
                name: <span className="font-weight-bold fs-13"style={{
                  fontSize:'0.9rem'
              }}>{column?.name}</span>,
                // selector: (row) => row[column.Value],
                cell: (row) => {
                  return (
                    <>
                    <div style={{ textAlign: "center" }} className="profile">
                      <img
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                        src={row.driver.profileImg ?? DEFAULT_AVATAR}
                        alt="Image"
                        style={{ width: "50px", height: "50px", objectFit: "cover", cursor: "pointer" }}
                        className="profile"
                        onClick={() => navigate(`/profile-details/${row.driver.id}`)}
                      />
                    </div>
                    <div style={{ textAlign: "center" }} className="profile">
                      <img
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                        src={row.user.profileImg ?? DEFAULT_AVATAR}
                        alt="Image"
                        style={{ width: "50px", height: "50px", objectFit: "cover", cursor: "pointer", position: "relative", left: "-10px", border: "1px", borderColor: "white" }}
                        className="profile"
                        onClick={() => navigate(`/user-details/${row.user.id}`)}
                      />
                    </div>
                    </>
                  );
                },
                wrap: true,
              };
              break;

            case "NORMAL":
                col = {
                    name: <span
                        className='font-weight-bold fs-13 text-lg'
                        style={{ fontSize: '0.9rem' }}
                    >
                        {column?.name}
                    </span>,
                    selector: row => {
                        const value = column.selector(row);
                        return value !== undefined ? value : null;
                    },
                    wrap: true,
                    minWidth: '8rem'
                };
                break;
                
            case "DESTINATION":
                col = {
                    name: <span className='font-weight-bold fs-13 text-lg' style={{
                        fontSize: '0.9rem'
                    }}>{column?.name}</span>,
                    selector: row => {
                        const value = column.selector(row);
                        return value !== undefined ? value : null;
                    },
                    wrap: true,
                    width: "22rem"
                };
                break;
            
            case "STATUS":
                col = {
                    name: <span
                        className='font-weight-bold fs-13 text-lg'
                        style={{ fontSize: '0.9rem' }}
                    >
                        {column?.name}
                    </span>,
                    selector: (row) => {
                        if (row?.status === 'COMPLETED') {
                            return <strong style={{ color: "green" }}>{row?.status}</strong>
                        }
                        return <strong style={{ color: "orange" }}>{row?.status}</strong>
                    },
                    wrap: true,
                    minWidth: '8rem'
                };
                break;

            case "DURATION":
                col = {
                    name: <span className='font-weight-bold fs-13 text-lg' style={{
                        fontSize: '0.9rem'
                    }}>{column?.name}</span>,
                    selector: (row) => {
                        const startedAt = row?.started_at;
                        const completedAt = row?.completed_at;

                        if (startedAt && completedAt) {
                            const duration = moment.duration(moment(completedAt).diff(moment(startedAt)));
                            const hours = Math.floor(duration.asHours());
                            const minutes = duration.minutes();
                            return `${hours}h ${minutes}m`;
                        }
                        return '0h 0m';
                    },
                    wrap: true,
                };
                break;

            case "DISTANCE":
                return col = {
                    name: <span className='font-weight-bold fs-13 text-lg' style={{
                        fontSize: '0.9rem'
                    }}>{column?.name}</span>,
                    selector: (row) => {
                        const distance = row?.ride_request?.distance;

                        return `${distance} Km`; // or any default value
                    },
                    wrap: true,
                };

            case "CREATEDAT":
                return col = {
                    name: <span className='font-weight-bold fs-13 text-lg' style={{
                        fontSize: '0.9rem'
                    }}>{column?.name}</span>,
                    selector: (row) => {
                        return `${(row?.ride_request?.created_at || '').split('T')[0]}`; // or any default value
                    },
                    wrap: true,
                };

            case "AMOUNT":
                return col = {
                    name: <span className='font-weight-bold fs-13 text-lg' style={{
                        fontSize: '0.9rem'
                    }}>{column?.name}</span>,
                    selector: (row) => {
                        const amount = row?.ride_request?.amount;
                        const currency = row?.ride_request?.currency;

                        return `${amount} ${currency || ''}`; // or any default value
                    },
                    wrap: true
                };
                break;
            default:
                col = {
                    name: <span className='font-weight-bold fs-13' style={{
                        fontSize: '0.9rem'
                    }}>{column?.name}</span>,
                    selector: row => {
                        return ['CreatedDate', 'ModifiedDate'].includes(column?.value)
                            ? new Date(row[column?.value]).toDateString()
                            : row[column?.value]
                    },
                    wrap: true
                };
                break;
        }
        return col;
    });
};
