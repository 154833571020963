import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

function Driver({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C10.355 2 9.009 3.345 9.009 5H15C15 3.345 13.655 2 12 2ZM6 7.5C6 6.67 6.67 6 7.5 6H16.5C17.33 6 18 6.67 18 7.5V8H6V7.5ZM2 9C2 8.45 2.45 8 3 8H21C21.55 8 22 8.45 22 9V20.5C22 21.33 21.33 22 20.5 22H3.5C2.67 22 2 21.33 2 20.5V9ZM4 12V20H20V12H4ZM8 15C8.55 15 9 15.45 9 16C9 16.55 8.55 17 8 17C7.45 17 7 16.55 7 16C7 15.45 7.45 15 8 15ZM17 15C17.55 15 18 15.45 18 16C18 16.55 17.55 17 17 17C16.45 17 16 16.55 16 16C16 15.45 16.45 15 17 15Z"
        fill={colors[color] ? colors[color].main : colors.dark.main}
      />
    </svg>
  );
}

Driver.defaultProps = {
  color: "dark",
  size: "16px",
};

Driver.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Driver;
