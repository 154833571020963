import { createSlice } from "@reduxjs/toolkit";

const intialState = {
    reportedRidesData:null,
    reportedRidesChart:null,
    loading: false,
};

const reportedRides = createSlice({
    name: "reportedRides",
    initialState: intialState,
    reducers: {
        reportedRidesRequest: (state) => {
            state.loading = true;
            },
        reportedRidesSuccess: (state, action) => {
            state.loading = false;
            state.reportedRidesData = action.payload;
            },
        reportedRidesFailure: (state) => {
            state.loading = false;
            },
        reportedRidesChat:(state,action)=>{
            state.loading=false;
            state.reportedRidesChart = action.payload;
        }
}
});

export const {reportedRidesChat,reportedRidesFailure,reportedRidesRequest,reportedRidesSuccess } = reportedRides.actions;
export default reportedRides.reducer;