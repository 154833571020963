import { toast } from "react-toast";
import { apiConnector } from "services/Connector";
import { ReportedRidesEndpoint } from "services/APIS";
import { reportedRidesRequest,reportedRidesFailure,reportedRidesSuccess } from "slices/ReportedRidesSlice";
const { GET_ALL_REPORTS } = ReportedRidesEndpoint;

export function getReportedRidesData() {
  return async (dispatch) => {
    try {
      dispatch(reportedRidesRequest(true));
      const response = await apiConnector("GET", GET_ALL_REPORTS);
      dispatch(reportedRidesSuccess(response.data.data));
      return true;
    } catch (error) {
      console.log("error: ", error);
      dispatch(reportedRidesFailure(false));
      return false;
    }
  };
}

