import { useDebugValue, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useDispatch, useSelector } from "react-redux";
import { verifyDriver } from "services/operations/DriverApi";
import { deleteUser } from "services/operations/UserApi";


function PlatformSettings({ user_id }) {
    const dispatch = useDispatch();
    const [verified, setVerified] = useState(false);
    const [answersPost, setAnswersPost] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [mentionsMe, setMentionsMe] = useState(true);
    const [newLaunches, setNewLaunches] = useState(false);
    const [productUpdate, setProductUpdate] = useState(true);
    const [newsletter, setNewsletter] = useState(true);

    const { Driver } = useSelector((state) => ({
        Driver: state.driver.driverDetails,
    }));


    useEffect(() => {
        if (Driver && Driver.driver) {
            setVerified(Driver.driver.verified);
        }
    }, [Driver]);

    useEffect(() => {
        dispatch(deleteUser({ user_id }));
    }, [deleted]);


    return (
        <Card>

            <SoftBox pt={2} px={2}>
                <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    platform settings
                </SoftTypography>
            </SoftBox>

            <SoftBox px={2} mt={3} mb={3} pb={4} lineHeight={1.25}>

                <SoftTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                    account
                </SoftTypography>

                <SoftBox display="flex" py={1} mb={0.25}>
                    <SoftBox mt={0.25}>
                        <Switch checked={verified} onChange={() => setVerified(!verified)} />
                    </SoftBox>

                    <SoftBox width="80%" ml={2}>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                            Verified
                        </SoftTypography>
                    </SoftBox>

                </SoftBox>

                <SoftBox display="flex" py={1} mb={0.25}>

                    <SoftBox mt={0.25}>
                        <Switch checked={deleted} onChange={() => setDeleted(!deleted)} />
                    </SoftBox>

                    <SoftBox width="80%" ml={2}>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                            Delete
                        </SoftTypography>
                    </SoftBox>

                </SoftBox>

                {/* <SoftBox display="flex" py={1} mb={0.25}>

                    <SoftBox mt={0.25}>
                        <Switch checked={mentionsMe} onChange={() => setMentionsMe(!mentionsMe)} />
                    </SoftBox>

                    <SoftBox width="80%" ml={2}>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                            Status
                        </SoftTypography>
                    </SoftBox>

                </SoftBox> */}

                <SoftBox mt={3}>
                    <SoftTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                        textTransform="uppercase"
                    >
                        application
                    </SoftTypography>
                </SoftBox>

                <SoftBox display="flex" py={1} mb={0.25}>

                    <SoftBox mt={0.25}>
                        <Switch checked={newLaunches} onChange={() => setNewLaunches(!newLaunches)} />
                    </SoftBox>

                    <SoftBox width="80%" ml={2}>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                            New launches and projects
                        </SoftTypography>
                    </SoftBox>

                </SoftBox>

                <SoftBox display="flex" py={1} mb={0.25}>

                    <SoftBox mt={0.25}>
                        <Switch checked={productUpdate} onChange={() => setProductUpdate(!productUpdate)} />
                    </SoftBox>

                    <SoftBox width="80%" ml={2}>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                            Monthly product updates
                        </SoftTypography>
                    </SoftBox>

                </SoftBox>

                <SoftBox display="flex" py={1} mb={0.25}>

                    <SoftBox mt={0.25}>
                        <Switch checked={newsletter} onChange={() => setNewsletter(!newsletter)} />
                    </SoftBox>

                    <SoftBox width="80%" ml={2}>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                            Subscribe to newsletter
                        </SoftTypography>
                    </SoftBox>

                </SoftBox>

            </SoftBox>

        </Card>
    );
}

PlatformSettings.propTypes = {
    user_id: PropTypes.shape({
        user_id: PropTypes.number.isRequired
    }).isRequired,
};

export default PlatformSettings;
