import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    users: [],
    userDetails: [],
    loading: false,
}

const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setLoading(state, value) {
            state.loading = value.payload
        },
        setUser(state, value) {
            state.users = value.payload
        },
        setUserDetails(state, value) {
            state.userDetails = value.payload
        },
        deleteUserSuccess(state, value) {
            state.users.filter((user) => user.user_id != value.payload);
        }

    }
});

export const { setUser, setLoading, setUserDetails , deleteUserSuccess} = userSlice.actions;
export default userSlice.reducer;