import { toast } from "react-toast";
import { setDriver, setDriverDetail, setLoading } from "../../slices/DriverSlice";
import { apiConnector } from "../Connector";
import { DriverrEndpoint } from "../APIS";

const {
  GET_ALL_DRIVER,
  GET_DRIVER_DETAILS,
  VERIFY_DRIVER,
  WITHDRAW_REQUEST,
  WITHDRAW_REQUEST_ACTION,
} = DriverrEndpoint;

export function getDrivers() {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConnector("GET", GET_ALL_DRIVER);
      if (response.data.data.length) {
        dispatch(setDriver(response.data.data));
        dispatch(setLoading(false));
        return true;
      }
      dispatch(setLoading(false));
    } catch (error) {
      console.log("error: ", error);
      toast.error(error.message);
      return false;
    }
  };
};

export function getDriverDetails(data) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConnector("POST", GET_DRIVER_DETAILS, data);
      if (response.data.data) {
        dispatch(setDriverDetail(response.data.data));
        dispatch(setLoading(false));
        return true;
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error(error.message);
      return false;
    }
  };
};

export function verifyDriver(data) {
  return async (dispatch) => {
    try {
      const response = await apiConnector("POST", VERIFY_DRIVER, data);
      if (response.data.data) {
        // dispatch(setDriverDetail(response.data.data));
        // return true;
        const response2 = await apiConnector("POST", GET_DRIVER_DETAILS, data);
        if (response2.data.data) {
          dispatch(setDriverDetail(response2.data.data));
          return true;
        }
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error(error.message);
      return false;
    }
  };
};

export function withdrawRequest() {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConnector("GET", WITHDRAW_REQUEST);
      if (response.data.data) {
        dispatch(setDriverDetail(response.data.data));
        dispatch(setLoading(false));
        return true;
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error(error.message);
      return false;
    }
  };
};

export function withdrawRequestAction(data) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      await apiConnector("POST", WITHDRAW_REQUEST_ACTION, data);
      dispatch(setLoading(false));
    } catch (error) { }
  };
};
