import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from 'services/operations/UserApi';
import DataTable from 'react-data-table-component';
import { columns } from './DataTableColumns';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import SoftBox from 'components/SoftBox';
import { useNavigate } from 'react-router-dom';
import { LoadingSpinner } from 'examples/LoadingSpinner/LoadingSpinner';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

const columnsNames = [
    {
        name: 'Profile',
        Value: 'profileImg',
        FieldName: 'IMAGE',
        selector: (row) => row.profileImg
    },
    {
        name: 'Name',
        Value: ['name', 'last_name'],
        FieldName: 'NAME',
        selector: (row) => `${row.name} ${row.last_name}`, // Combine name and last_name
    },
    {
        name: 'Email',
        Value: 'email',
        FieldName: 'NORMAL',
        selector: (row) => row.email
    },
    {
        name: 'Phone',
        Value: 'phone',
        FieldName: 'NORMAL',
        selector: (row) => row.phone
    },
    {
      name: 'Rides',
      Value: 'rides',
      FieldName: 'NORMAL',
      selector: (row) => row.rides,
    },
];

const Users = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState();
    const searchFilter = useSelector((state) => state.search.search);
    const { Users, Loading, Country } = useSelector((state) => ({
        Users: state.user.users,
        Loading: state.user.loading,
        Country: state.country.country
    }));

    const handleRowClick = (row) => {
        navigate(`/user-details/${row.user_id}`)
    };

    useEffect(() => {
        setLoading(Loading)
    }, [Loading])

    useEffect(() => {
        dispatch(getUsers());
    }, [Country, dispatch])

    const filteredUsers = Array.isArray(Users) ? Users.filter(user => {
        const name = `${user?.name ?? 'N/A'} ${user?.last_name ?? ''}`.toLowerCase();
        const email = (user?.email ?? '').toLowerCase();
        const phone = (user.phone).toString();
        const searchTerm = (searchFilter ?? '').toLowerCase();

        return name.includes(searchTerm) || email.includes(searchTerm) || phone.includes(searchTerm);
    }) : [];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox mt={5} mb={5}>
                <DataTable
                    // title={"User List"}
                    data={filteredUsers.length ? filteredUsers : []}
                    columns={columns(columnsNames)}
                    highlightOnHover
                    pointerOnHover
                    progressPending={loading}
                    progressComponent={<LoadingSpinner />}
                    striped
                    onRowClicked={handleRowClick}
                    pagination
                />
            </SoftBox>
        </DashboardLayout>
    )
}

export default Users 