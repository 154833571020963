import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Container,
  Box,
  Input,
} from "@mui/material";
import { deleteAccount } from "services/operations/DeleteAccountApi";

function DeleteAccountPage() {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar message

  // Handle the confirmation dialog open/close
  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  // Handle Snackbar (Success Message) open/close
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Handle delete account (API call)
  const handleDeleteAccount = async () => {
    try {
      const response = await deleteAccount({ email: email });
      console.log('response.status: ', response.code);
      
      if (response.code === 200) {
        setSnackbarMessage("Account successfully deleted!"); // Set success message
        setEmail(""); // Clear email input
      } else {
        setSnackbarMessage(response.message || "An error occurred.");
        setEmail("") // Set error message from response
      }
      
      setOpenConfirmDialog(false);
      setOpenSnackbar(true);
      console.log(response);
    } catch (error) {
      console.log('error: ', error);
      setSnackbarMessage("An error occurred while deleting the account."); // Set error message on catch
      setOpenSnackbar(true);
    }
  };

  // Email validation
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Handle email input change and validation
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  return (
    <Container
      maxWidth="sm"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "50%", // Ensure the Box takes the full width
          px: 2, // Add padding for smaller screens
        }}
      >
        <Input
          type="email"
          aria-label="Demo input"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
          error={!isEmailValid && email.length > 0} // Show error only when email is invalid and not empty
          sx={{ mb: 2 }} // Add margin bottom for better spacing
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleOpenConfirmDialog}
          disabled={!isEmailValid} // Disable button if email is not valid
          sx={{ mt: "2rem" }}
        >
          Delete Account
        </Button>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Account Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your account associated with <strong>{email}</strong>?
            This action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAccount} color="error">
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage} // Use the snackbarMessage state
      />
    </Container>
  );
}

export default DeleteAccountPage;
