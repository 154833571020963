import Dashboard from "layouts/dashboard";
import Tables from "layouts/reported-rides";
import Users from "layouts/users";
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import CustomerSupport from "examples/Icons/CustomerSupport";
import Cube from "examples/Icons/Cube";
import User from "examples/Icons/Users";
import Driver from "layouts/driver";
import DriverLogo from "examples/Icons/Driver";
import Rides from "layouts/rides";
import ProfileDetails from "layouts/profile-detail";
import UserDetails from "layouts/user-details";
import Signout from "layouts/authentication/sign-out/Signout";
import Emergency from "layouts/emergency";
import Forgotpassword from "layouts/authentication/forgot-password";
import DeleteAccountPage from "layouts/Delete";
import PaymentRequests from "layouts/payment-requests";


const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
    showInNav: true,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    icon: <User size="12px" />,
    component: <Users />,
    noCollapse: true,
    showInNav: true,
  },
  {
    type: "collapse",
    name: "Drivers",
    key: "drivers",
    route: "/drivers",
    icon: <DriverLogo size="12px" />,
    component: <Driver />,
    noCollapse: true,
    showInNav: true,
  },
  {
    type: "collapse",
    name: "Rides",
    key: "rides",
    route: "/rides",
    icon: <Shop size="12px" />,
    component: <Rides />,
    noCollapse: true,
    showInNav: true,
  },
  {
    name: "Profile",
    key: "profile",
    route: "/profile-details/:user_id",
    icon: <CustomerSupport size="12px" />,
    component: <ProfileDetails />,
    noCollapse: true,
    showInNav: false,
  },
  {
    name: "Profile",
    key: "profile",
    route: "/user-details/:user_id",
    icon: <CustomerSupport size="12px" />,
    component: <UserDetails />,
    noCollapse: true,
    showInNav: false,
  },
  {
    type: "collapse",
    name: "Reported Rides",
    key: "reported-Rides",
    route: "/reported-Rides",
    icon: <Office size="12px" />,
    component: <Tables />,
    noCollapse: true,
    showInNav: true,
  },
  {
    type: "collapse",
    name: "Requests",
    key: "requests",
    route: "/requests",
    icon: <Cube size="12px" />,
    component: <PaymentRequests />,
    noCollapse: true,
    showInNav: true,
  },
  {
    type: "collapse",
    name: "SOS",
    key: "emergency",
    route: "/emergency",
    icon: <Office size="12px" />,
    component: <Emergency />,
    noCollapse: true,
    showInNav: true,
  },
  // { type: "title", title: "Account Pages", key: "account-pages" },
  {
    type: "collapse",
    name: "Sign out",
    key: "sign-out",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: <Signout />,
    noCollapse: true,
    showInNav: true,
  },
  {
    name: "forgot-password",
    key: "forgotpassword",
    route: "/forgotpassword",
    component: <Forgotpassword />,
  },
  {
    name: "delete-account",
    key: "deleteaccount",
    route: "/deleteaccount",
    component: <DeleteAccountPage />,
  },
];

export default routes;
