import { toast } from "react-toast";
import { apiConnector } from "services/Connector";
import { setLoading } from "slices/ReportedChats";
import { ReportedChatsEndPoint } from "services/APIS";
import { setFailure } from "slices/ReportedChats";
import { setReportedRidesChat } from "slices/ReportedChats";
const { GET_ALL_CHATS,REPLY_CHATS } = ReportedChatsEndPoint;

export function getChats(id) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConnector("POST",GET_ALL_CHATS,{
        "ride_id": id
      });
      dispatch(setReportedRidesChat(response.data.data));
      dispatch(setLoading(false))
      return true;
    } catch (error) {
      console.log("error: ", error);
      dispatch(setFailure(false));
      return false;
    }
  };
}
export function replyChat(data){
  return async (dispatch) => {
    try {
      const response = await apiConnector("POST",REPLY_CHATS,data);
      console.log("response: ", response);
      return true;
      } catch (error) {
        console.log("error: ", error);
        dispatch(setFailure(false));
        return false;
        } 
    }
}