import { Switch } from "@mui/material";
import SoftBox from "components/SoftBox";
import { useDispatch } from "react-redux";
import { verifyDriver } from "services/operations/DriverApi";

const DEFAULT_AVATAR = "https://avatar.iran.liara.run/public/29";

export const columns = (columnNames, rowStates, setRowStates) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  const changeHandler = (row) => {
    const updatedRowState = !rowStates[row.user_id];
    setRowStates((prevStates) => ({
      ...prevStates,
      [row.user_id]: updatedRowState,
    }));
    dispatch(verifyDriver({ user_id: row.user_id, status: updatedRowState }));
  };

  return columnNames?.map((column) => {
    let col;
    switch (column?.FieldName) {
      case "IMAGE":
        return (col = {
          name: <span className="font-weight-bold fs-13"style={{
            fontSize:'0.9rem'
        }}>{column?.name}</span>,
          selector: (row) => row[column.Value],
          cell: (row) => {
            const imageUrl = row[column.Value] ? `${row[column.Value]}` : DEFAULT_AVATAR;
            return (
              <div style={{ textAlign: "center" }} className="profile">
                <img
                // eslint-disable-next-line react-hooks/rules-of-hooks
                  src={imageUrl}
                  alt="Image"
                  style={{ width: "50px", height: "50px", objectFit: "cover", cursor: "pointer" }}
                  className="profile"
                  //onClick={() => navigate(`/profile-details/${userId}`)}
                />
              </div>
            );
          },
          wrap: true,
        });
      case "NAME": {
        return (col = {
          name: <span className="font-weight-bold fs-13"style={{
            fontSize:'0.9rem'
        }}>{column?.name}</span>,
          selector: (row) => row[column.Value],
          cell: (row) => {
            return row.name
              ? row?.name + " " + (row?.last_name === null ? "" : row.last_name)
              : "N/A";
          },
        });
      }
      case "NORMAL":
        return (col = {
          name: <span className="font-weight-bold fs-13 text-lg"style={{
            fontSize:'0.9rem'
        }}>{column?.name}</span>,
          selector: (row) => {
            return row[column.Value];
          },
          wrap: true,
        });

      case "BUTTON":
        return (col = {
          name: <span className="font-weight-bold fs-13 text-lg"style={{
            fontSize:'0.9rem'
        }}>Verify</span>,
          selector: (row) => {
            return (
              <SoftBox mt={0.25}>
                <Switch
                  checked={rowStates[row.user_id] ?? row.driver.verified}
                  onChange={() => changeHandler(row)}
                />
              </SoftBox>
            );
          },
          wrap: true,
        });

      case "STATUS":
        return (col = {
          name: <span className="font-weight-bold fs-13 text-lg"style={{
            fontSize:'0.9rem'
        }}>Status</span>,
          selector: (row) => {
            return (
              <SoftBox mt={0.25}>
                {row.driver.verified
                  ? (row.driver.driver_status
                    ? <strong style={{ color: "green" }}>VERIFIED</strong>
                    : <strong style={{ color: "orange" }}>SUSPENDED</strong>)
                  : <strong style={{ color: "red" }}>PENDING</strong>}
              </SoftBox>
            );
          },
          wrap: true,
        });
        
      default:
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return (col = {
        name: <span className="font-weight-bold fs-13"style={{
          fontSize:'0.9rem'
      }}>{column?.name}</span>,
        selector: (row) => {
          return ["CreatedDate", "ModifiedDate"].includes(column?.Title)
            ? new Date(row[column?.Title]).toDateString()
            : row[column?.Title];
        },
        wrap: true,
      });
    }
  });
};
