import { apiConnector } from "services/Connector";
import { DashboardEndpoints } from "../APIS";
import { setLoading, setDashboardData } from "slices/DashboardSlice";
import { setDashboardGraph } from "slices/DashboardSlice";
const { GET_DASHBOARD_DATA, GET_DASHBOARD_GRAPH_DATA } = DashboardEndpoints;

export function getDashboardData() {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await apiConnector("GET", GET_DASHBOARD_DATA);
            dispatch(setDashboardData(response.data.data));
            dispatch(setLoading(false));
            return true;
        } catch (error) {
            console.log('error: ', error);
            dispatch(setLoading(false));
            return false;
        }
    }
};

export function getDashboardGraph() {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await apiConnector("GET", GET_DASHBOARD_GRAPH_DATA);
            dispatch(setDashboardGraph(response.data.data));
            dispatch(setLoading(false));
            return true;
        } catch (error) {
            console.log('error: ', error);
            dispatch(setLoading(false));
            return false;
        }
    }
};