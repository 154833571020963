import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notifications: [],
    messages: [],
    // Add other socket-related state here
};

const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {
        sosData(state, action) {
            state.notifications=action.payload;
        },
        
        // Add other reducers as needed
    },
});

export const { sosData } = socketSlice.actions;
export default socketSlice.reducer;
