const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const AuthEndpoints = {
  SIGNUP_API: API_BASE_URL + "/v0/auth/signup",
  LOGIN_API: API_BASE_URL + "/v0/admin/login",
  RESETPASSTOKEN_API: API_BASE_URL + "/v0/auth/reset-password-token",
  RESETPASSWORD_API: API_BASE_URL + "/v0/admin/reset-password",
  FORGOTPASSWORD_API: API_BASE_URL + "/v0/admin/forgot-password",
  VERIFY_OTP: API_BASE_URL + "/v0/admin/verify-otp"
}
export const DeleteAccount = {
  DELETE_ACCOUNT: API_BASE_URL + "/v0/user/deleteUserWeb"
}
export const DashboardEndpoints = {
  GET_DASHBOARD_DATA: API_BASE_URL + "/v0/admin/getDashboardData",
  GET_DASHBOARD_GRAPH_DATA: API_BASE_URL + "/v0/admin/getYearlyData",
}

export const UserEndpoint = {
  GET_ALL_USER: API_BASE_URL + "/v0/admin/getAllUser",
  GET_USER_DETAILS: API_BASE_URL + "/v0/admin/getUserDetails",
  DELETE_USER: API_BASE_URL + "/v0/admin/deleteUser"
}

export const DriverrEndpoint = {
  GET_ALL_DRIVER: API_BASE_URL + "/v0/admin/getAllDrivers",
  GET_DRIVER_DETAILS: API_BASE_URL + "/v0/admin/getDriverDetails",
  VERIFY_DRIVER: API_BASE_URL + "/v0/admin/verifyDriver",
  WITHDRAW_REQUEST: API_BASE_URL + "/v0/admin/getAllWithdrawalReuests",
  WITHDRAW_REQUEST_ACTION: API_BASE_URL + "/v0/admin/updateWithdrawalRequest"
}

export const TopupEndpoint = {
  GET_TOPUP_REQUESTS: API_BASE_URL + "/v0/admin/getAllTopupRequest",
  ACKNOWLEDGE_TOPUP_REQUEST: API_BASE_URL + "/v0/admin/acknowledgeTopupRequest"
}

export const RidesEndpoint = {
  GET_ALL_RIDES: API_BASE_URL + "/v0/admin/getTodaysRide",
}

export const ReportedRidesEndpoint = {
  GET_ALL_REPORTS: API_BASE_URL + "/v0/admin/getAllReports",
}

export const ReportedChatsEndPoint = {
  GET_ALL_CHATS: API_BASE_URL + "/v0/admin/getReportDetail",
  REPLY_CHATS: API_BASE_URL + "/v0/admin/replyReport"
}