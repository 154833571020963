import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";

// Data
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDashboardData, getDashboardGraph } from "../../services/operations/DashboardApi";
import { transformBarData } from "./data/transformBarData";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { Dashboard, Graphdata, Country } = useSelector((state) => ({
    Dashboard: state.dashboard.dashboardData,
    Graphdata: state.dashboard.dashboardGraph,
    Country: state.country.country
  }));

  useEffect(() => {
    // const cookieValue = Cookies.get('cookieName');

    // const token = Cookies.get("jwt");
    // console.log('jwt: ', token);
    //  // Replace "token" with your cookie name
    // if (token) {
    //   console.log("Token from cookies:", token);
    //   // You can perform additional logic here, like authentication
    // } else {
    //   console.log("No token found in cookies");
    // }
    dispatch(getDashboardData());
    dispatch(getDashboardGraph());
  }, [dispatch, Country]);

  // useEffect(() => {
  //   dispatch(getDashboardGraph());
  //   dispatch(getDashboardData());
  // }, [Country])


  // const lineGraphData = useMemo(() => transformGraphData(Graphdata), [Graphdata]);
  const barGraphData = useMemo(() => transformBarData(Graphdata), [Graphdata]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>

        <SoftBox mb={3}>
          <Grid container spacing={3}>

            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Registered users" }}
                count={Dashboard?.userCount ? (Dashboard?.userCount) : ('0')}
                icon={{ color: "info", component: "public" }}
                onClick={(() => navigate("/users"))}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Registered drivers" }}
                count={Dashboard?.driverCount ? (Dashboard?.driverCount) : ('0')}
                //percentage={{ color: "success", text: "+3%" }}
                icon={{ color: "info", component: "public" }}
                onClick={(() => navigate("/drivers"))}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Completed rides" }}
                count={Dashboard?.rideCount ? (Dashboard?.rideCount) : ('0')}
                onClick={(() => navigate("/rides"))}
                icon={{ color: "info", component: "emoji_events" }}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Sales" }}
                count={`
                    ${Dashboard?.sales ? (Dashboard.sales) : ('0')} ${Dashboard?.currency ? Dashboard.currency : ''}
                `}
                icon={{ color: "info", component: "paid" }}
              />
            </Grid>

          </Grid>
        </SoftBox>

        <SoftBox mb={3}>

        </SoftBox>

        <SoftBox mb={3}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} lg={7}>
              <GradientLineChart
                title="Sales Overview"
                // description={
                //   <SoftBox display="flex" alignItems="center">
                //     <SoftBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                //       <Icon className="font-bold">arrow_upward</Icon>
                //     </SoftBox>
                //     <SoftTypography variant="button" color="text" fontWeight="medium">
                //       4% more{" "}
                //       <SoftTypography variant="button" color="text" fontWeight="regular">
                //         in 2021
                //       </SoftTypography>
                //     </SoftTypography>
                //   </SoftBox>
                // }
                height="20.25rem"
                chart={lineGraphData}
              />
            </Grid> */}
            <Grid item xs={12} lg={12}>
              <ReportsBarChart
                title="Monthly Sales"
                // description={
                //   <>
                //     (<strong>+23%</strong>) than last week
                //   </>
                // }
                chart={barGraphData}
              />
            </Grid>
          </Grid>
        </SoftBox>

      </SoftBox>

    </DashboardLayout>
  );
}

export default Dashboard;
