import { apiConnector } from "services/Connector";
import { TopupEndpoint } from "../APIS";
import { setLoading, setTopupRequests, removeTopupRequest } from "../../slices/TopupSlice";
import { toast } from "react-toastify";
const { GET_TOPUP_REQUESTS, ACKNOWLEDGE_TOPUP_REQUEST } = TopupEndpoint;


export function fetchTopupRequest() {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await apiConnector("GET", GET_TOPUP_REQUESTS)
            console.log('response: ', response);
            dispatch(setTopupRequests(response.data.data))
            dispatch(setLoading(false));
        } catch (error) {
            console.log('error: ', error);
            dispatch(setLoading(false));
        }
    }
};

export function acknowledgeTopupRequest(data) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", ACKNOWLEDGE_TOPUP_REQUEST, data)
            dispatch(removeTopupRequest(response.data.data.payment_image_id))
            toast.success(response.data.message)
        } catch (error) {
            console.log('error: ', error);
            dispatch(setLoading(false));
        }
    }
};
