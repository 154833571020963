import React from 'react';
import { Input } from 'reactstrap';

export const columns = (columnNames, handleImageClick, handleAction, actionLoading, handleAmountChange, amounts) => {
    return columnNames?.map((column) => {
        let col;
        switch (column?.fieldName) {
            case "NORMAL":
                return col = {
                    name: (
                        <span className="font-weight-bold fs-13 text-lg" style={{ fontSize: '0.9rem' }}>
                            {column?.name}
                        </span>
                    ),
                    selector: (row) => {
                        const value = column.selector(row);
                        return value !== undefined ? value : 'N/A';
                    },
                    wrap: true,
                    minWidth: '8rem'
                };

            case "SCREENSHOT":
                return col = {
                    name: (
                        <span className="font-weight-bold fs-13 text-lg" style={{ fontSize: '0.9rem' }}>
                            {column?.name}
                        </span>
                    ),
                    cell: (row) => (
                        <img
                            src={row.image_url}
                            alt="Payment Screenshot"
                            style={{ width: '100px', height: 'auto', borderRadius: '5px', cursor: 'pointer' }}
                            onClick={() => handleImageClick(row.image_url)}
                        />
                    ),
                    wrap: true,
                    minWidth: '12rem',
                    center: true
                };

            case "AMOUNT":
                return col = {
                    name: (
                        <span className="font-weight-bold fs-13 text-lg" style={{ fontSize: '0.9rem' }}>
                            {column?.name}
                        </span>
                    ),
                    cell: (row) => (
                        <Input
                            type="number"
                            value={amounts[row.payment_image_id] || 0}
                            onChange={(e) => handleAmountChange(row.payment_image_id, e.target.value)}
                            style={{
                                padding: '5px',
                                fontSize: '0.9rem',
                                width: '6rem',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                textAlign: 'center'
                            }}
                        />
                    ),
                    wrap: true,
                    minWidth: '8rem',
                    center: true
                };

            case "ACTION":
                return col = {
                    name: (
                        <span className="font-weight-bold fs-13 text-lg" style={{ fontSize: '0.9rem' }}>
                            {column?.name}
                        </span>
                    ),
                    cell: (row) => (
                        <div
                            style={{
                                display: 'flex',
                                gap: '10px',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {actionLoading === row.payment_image_id ? (
                                <span>Loading...</span>
                            ) : (
                                <>
                                    <button
                                        style={{
                                            padding: '12px 14px',
                                            backgroundColor: '#FFE134',
                                            color: 'grey',
                                            fontWeight: 'bold',
                                            border: 'none',
                                            borderRadius: '6px',
                                            cursor: 'pointer',
                                            minWidth: '85px',  // Ensures buttons are at least this width
                                            maxWidth: '150px', // Limits width on larger screens
                                            flex: '1 1 auto'   // Adjusts to available space but keeps row layout
                                        }}
                                        onClick={() => handleAction(row, 'true')}
                                    >
                                        Approve
                                    </button>
                                    <button
                                        style={{
                                            padding: '12px 14px',
                                            backgroundColor: 'white',
                                            color: 'grey',
                                            borderBottom: '2px solid rgb(103, 116, 142)',
                                            fontWeight: 'bold',
                                            border: 'none',
                                            borderRadius: '6px',
                                            cursor: 'pointer',
                                            minWidth: '85px',
                                            maxWidth: '150px',
                                            flex: '1 1 auto'
                                        }}
                                        onClick={() => handleAction(row, 'false')}
                                    >
                                        Reject
                                    </button>
                                </>
                            )}
                        </div>
                    ),
                    wrap: true,
                    minWidth: '8rem',
                    center: true
                };

            default:
                col = {
                    name: (
                        <span className="font-weight-bold fs-13" style={{ fontSize: '0.9rem' }}>
                            {column?.name}
                        </span>
                    ),
                    selector: (row) => row[column?.fieldName] || 'N/A',
                    wrap: true
                };
                break;
        }
        return col;
    });
};
