import React, { useEffect } from 'react'

const Signout = () => {

  useEffect(() => {
    localStorage.removeItem('auth');
    localStorage.removeItem('country');
  });

  return (
    <div></div>
  )
}

export default Signout