import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Button } from '@mui/material';

export const LoadingSpinner = ({ size, text, button }) => {
  const spinnerSize = size === 'small' ? 20 : 30;

  return (
    <>
      {button ? (
        <Button  size="small" variant="contained" color="secondary" disabled>
          <CircularProgress color='secondary' size={spinnerSize} />
        </Button>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' ,padding:'10px'}}>
          <CircularProgress  color='secondary' size={spinnerSize} />
        </div>
      )}
    </>
  );
};

LoadingSpinner.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
  text: PropTypes.string,
  button: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  size: 'large',
  text: 'Loading...',
  button: false,
};

