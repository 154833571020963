import { getSocket, disconnectSocket } from '../sockets/Socket';
import { sosData } from '../slices/SocketSlice';
import { setUserData } from '../slices/AuthSlice';

const socketMiddleware = (store) => (next) => (action) => {

    // switch (action.type) {
    //     case setUserData.type: {
    //         getSocket();

    //         break;
    //     }
    //     case 'auth/userLoggedOut': // Assuming you have a userLoggedOut action
    //         disconnectSocket();
    //         break;

    //     default:
    //         break;
    // }

    return next(action);
};

export default socketMiddleware;
