import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import CircularProgress from '@mui/material/CircularProgress';

import Header from "./components/Header";
import PlatformSettings from "./components/PlatformSettings";

import profilesListData from "layouts/profile/data/profilesListData";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { columns } from "./DataTableColumns";
import { getUserDetails } from "services/operations/UserApi";
import { Box, Table, TableContainer } from "@mui/material";

const columnsNames = [
    {
        name: 'Driver',
        FieldName: 'PROFILE',
    },
    {
        name: 'Source',
        Value: 'ride_request.source_name',
        FieldName: 'NORMAL',
        minWidth:'250px',
        selector: (row) => row?.ride_request?.source_name
    },
    {
        name: 'Destination',
        Value: 'destination_name',
        FieldName: 'NORMAL',
        minWidth:'250px',
        selector: (row) => row?.ride_request?.destination_name
    },
    {
        name: 'Distance',
        Value: 'distance',
        FieldName: 'DISTANCE',
        selector: (row) => row?.ride_request?.distance
    },
    {
        name: 'Duration',
        FieldName: 'DURATION',
    },
    {
        name: 'Status',
        Value: 'status',
        Title:'status',
        selector: (row) => row?.status
    },
];

const UserDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { user_id } = useParams();
    const [selectedTab, setSelectedTab] = useState(0);
    const [userLoading,serUserLoading]=useState(true)
    useEffect(() => {
        // 
        const fetchDriverDetails = async () => {
            await dispatch(getUserDetails({ user_id }));
            serUserLoading(false);
        };
    
        fetchDriverDetails();
    }, [dispatch,user_id]);

    const { User, Loading } = useSelector((state) => ({
        User: state.user.userDetails,
        Loading: state.user.loading,
    }));

    useEffect(() => {
        setLoading(Loading);
    }, [Loading]);

    return (    
        <DashboardLayout>
            {userLoading?(<Box sx={{ display: 'flex' ,justifyContent:'center'}}>
                <CircularProgress />
                </Box>):(<>
            <Header Driver={User} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

            {selectedTab === 1 && (
                <SoftBox mt={5} mb={3}>
                    <Grid container spacing={3}>
                        {/* <Grid item xs={12} md={6} xl={4}>
                            <PlatformSettings user_id={user_id} />
                        </Grid> */}

                        <Grid item xs={12} md={6} xl={4}>
                            <ProfileInfoCard
                                title="profile information"
                                info={{
                                    fullName: User?.name,
                                    mobile: User?.phone,
                                    email: User?.email,
                                    wallet: `${User?.wallet?.amount} ${User?.wallet?.currency}`
                                }}
                                social={[
                                    {
                                        link: "https://www.facebook.com/CreativeTim/",
                                        icon: <FacebookIcon />,
                                        color: "facebook",
                                    },
                                    {
                                        link: "https://twitter.com/creativetim",
                                        icon: <TwitterIcon />,
                                        color: "twitter",
                                    },
                                    {
                                        link: "https://www.instagram.com/creativetimofficial/",
                                        icon: <InstagramIcon />,
                                        color: "instagram",
                                    },
                                ]}
                                action={{ route: "", tooltip: "Edit Profile" }}
                            />
                        </Grid>

                        {/* <Grid item xs={12} xl={4}>
                            <ProfilesList title="conversations" profiles={profilesListData} />
                        </Grid> */}
                    </Grid>
                </SoftBox>
            )}

            {selectedTab === 0 && (
                <SoftBox mt={5} mb={3}>
                    <Card>
                        <SoftBox pt={2} px={2}>
                                <SoftTypography variant="h6" fontWeight="medium">
                                    {"All rides"}
                                </SoftTypography>
                        </SoftBox>
                        <TableContainer p={2}> 
                            <Table>
                            <DataTable
                                data={User?.rides}
                                columns={columns(columnsNames, navigate)}
                                highlightOnHover
                                progressPending={loading}
                                striped
                                pagination
                            />
                            </Table>
                        </TableContainer>
                    </Card>
                </SoftBox>
            )}

            {selectedTab === 2 && (
                <SoftBox mt={5} mb={3}>
                    {/* Content for the third tab */}
                </SoftBox>
            )}</>)}

        </DashboardLayout>
    );
};

export default UserDetails;
