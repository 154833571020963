import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import { Author } from "./data/authorsTableData";
import { authorsTableData } from "layouts/reported-rides/data/authorsTableData";
import { getReportedRidesData } from "services/operations/ReportedRidesApi";
import ChatView from "./chat-view/ChatView";

function Tables() {
  const dispatch = useDispatch();
  const { columns } = authorsTableData;
  const [selectedReport, setSelectedReport] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const isXsDown = useMediaQuery("(max-width:879px)",{ noSsr: true });

  const reportedData = useSelector((state) => state.reportedRides.reportedRidesData); // Adjust this selector according to your state structure
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    dispatch(getReportedRidesData());
  }, [dispatch]);

  // Mapping the data to the required format
  const rowsData = reportedData?.map((report) => ({
    User: (
      <Author
        image={report.profileImg}
        name={report.name ? `${report.name} ${report.last_name}` : 'N/A'}
        email={report.email}
      />
    ),
    Action: (
      <SoftTypography
        component="a"
        href="#"
        variant="caption"
        color="secondary"
        fontWeight="medium"
        onClick={(e) => {
          e.stopPropagation(); // Prevents the row click handler from firing
          setSelectedReport(report);
          if (isMobile) {
            setOpenDialog(true);
          }
        }}
      >
        Reply
      </SoftTypography>
    ),
    onClick: () => { // Add onClick handler for the entire row
      setSelectedReport(report);
      if (isMobile) {
        setOpenDialog(true);
      }
    },
  }));
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox sx={{ height: '80vh' }} py={3}>
        <Grid container spacing={3} style={{ maxHeight: 'calc(85vh - 100px)', overflow: 'hidden' }}>
          <Grid item xs={12} lg={4} style={{ maxHeight: 'calc(90vh - 100px)'}}>
            <SoftBox shadow='none' mb={3}>
              <Card sx={{ boxShadow: 'none' }}>
                {/* <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <SoftTypography variant="h6">Reported Rides</SoftTypography>
                </SoftBox> */}
                <SoftBox
                  sx={{
                    "& .MuiTableContainer-root": { height: '60vh' },
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={columns} rows={rowsData} />
                </SoftBox>
              </Card>
            </SoftBox>
          </Grid>
          {!isXsDown && (
            <Grid item xs={12} lg={8}>
              <SoftBox>
                {selectedReport && <ChatView report={selectedReport} />}
              </SoftBox>
            </Grid>
          )}
        </Grid>
      </SoftBox>
      {isXsDown && (
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="md">
          <DialogContent sx={{ display:'flex',mt:'10px', borderRadius:'0px'}}>
            {selectedReport && <ChatView report={selectedReport} />}
          </DialogContent>
        </Dialog>
      )}
    </DashboardLayout>
  );
}

export default Tables;
