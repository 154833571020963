import moment from "moment";
const DEFAULT_AVATAR = "https://avatar.iran.liara.run/public/29";

export const columns = (columnNames, navigate) => {
  let col;
  return columnNames?.map((column) => {
    switch (column?.FieldName) {
      case "PROFILE":
        return col = {
          name: <span className="font-weight-bold fs-13"style={{
            fontSize:'0.9rem'
        }}>{column?.name}</span>,
          // selector: (row) => row[column.Value],
          cell: (row) => {
            return (
              <div style={{ textAlign: "center" }} className="profile">
                <img
                // eslint-disable-next-line react-hooks/rules-of-hooks
                  src={row.user.profileImg ?? DEFAULT_AVATAR}
                  alt="Image"
                  style={{ width: "50px", height: "50px", objectFit: "cover", cursor: "pointer" }}
                  className="profile"
                  onClick={() => navigate(`/user-details/${row.user.id}`)}
                />
              </div>
            );
          },
          wrap: true,
        };
      case "NORMAL":
        return (col = {
          name: <span className="font-weight-bold fs-13 text-lg"style={{
            fontSize:'0.9rem'
        }}>{column?.name}</span>,
          selector: (row) => {
            const value = column.selector(row);
            return value !== undefined ? value : null;
          },
          wrap: true,
        width: "18rem"
        });
      case "DISTANCE":
        return (col = {
          name: <span className="font-weight-bold fs-13 text-lg"style={{
            fontSize:'0.9rem'
        }}>{column?.name}</span>,
          selector: (row) => {
            const distance = row?.ride_request?.distance;

            return `${distance} Km`; // or any default value
          },
          wrap: true,
        });
        break;
      case "DURATION":
        return (col = {
          name: <span className="font-weight-bold fs-13 text-lg"style={{
            fontSize:'0.9rem'
        }}>{column?.name}</span>,
          selector: (row) => {
            const startedAt = row?.started_at;
            const completedAt = row?.completed_at;

            if (startedAt && completedAt) {
              const duration = moment.duration(moment(completedAt).diff(moment(startedAt)));
              const hours = Math.floor(duration.asHours());
              const minutes = duration.minutes();
              return `${hours}h ${minutes}m`;
            }
            return "N/A";
          },
          wrap: true,
        });

      default:
        return (col = {
          name: <span className="font-weight-bold fs-13"style={{
            fontSize:'0.9rem'
        }}>{column?.name}</span>,
          selector: (row) => {
            return ["CreatedDate", "ModifiedDate"].includes(column?.Title)
              ? new Date(row[column?.Title]).toDateString()
              : row[column?.Title];
          },
          wrap: true,
          width:'8rem'
        });
    }
  });
};
