import { toast,Bounce } from "react-toastify";
import { setLoading, setToken, setUserData } from "../../slices/AuthSlice";
import { apiConnector } from "../Connector";
import { AuthEndpoints } from "../APIS";
import { setForgotPasswordData } from "slices/ForgetPassword";
const { SIGNUP_API, LOGIN_API,VERIFY_OTP,RESETPASSWORD_API,FORGOTPASSWORD_API } = AuthEndpoints;

export function signup(data) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConnector("POST", SIGNUP_API, data);
      toast.success("Signup Successful",{
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true, 
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
      dispatch(setLoading(false));
      dispatch(setUserData(response.data.data));
      localStorage.setItem("auth", response.data.data.token);
      return true;
    } catch (error) {
      toast.error(error.response.data.message);
      dispatch(setLoading(false));
      return false;
    }
  };
}

export function login(data) {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await apiConnector("POST", LOGIN_API, data);
            if (!response.data.success) throw new Error(response.data.message);
            toast.success("Login Successful", { position: 'top-right' });
            dispatch(setLoading(false));
            dispatch(setUserData(response.data.data))
            localStorage.setItem("auth", response.data.data.token);
            return true;
        } catch (error) {
            toast.error(error?.response?.data?.message);
            dispatch(setLoading(false));
            return false;
        }
    }
};

