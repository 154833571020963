export const transformBarData = (graphData) => {
    if (!graphData) {
      return {
        labels: [],
        datasets: [],
      };
    }
  
    const labels = graphData.map((data) => data.month);
    const dataValues = graphData.map((data) => data.total_income);
  
    return {
      labels,
      datasets: {
        label: "Total Income",
        data: dataValues,
      },
    };
  };
  