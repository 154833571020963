import React, { useState, useEffect } from 'react';
import Topup from 'layouts/topup';
import Withdraw from 'layouts/withdraw';
import { useSelector } from 'react-redux';

const PaymentRequests = () => {
    const { Country } = useSelector((state) => ({ Country: state.country.country }));
    const [component, setComponent] = useState(<Topup />) // TODO: not working now

    // TODO: not working now
    // useEffect(() => {
    //     if (Country == "GHANA") setComponent(<Withdraw />);
    //     if (Country == "LIBERIA") setComponent(<Topup />)
    // }, [Country])

    return <>{component}</>;
}

export default PaymentRequests;
