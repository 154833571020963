import { toast } from "react-toast";
import { apiConnector } from "../Connector";
import { RidesEndpoint } from "../APIS";
import { setRides, setRideDetails, setLoading } from "slices/RideSlice";
const { GET_ALL_RIDES } = RidesEndpoint;

export function getRides() {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConnector("GET", GET_ALL_RIDES);
      if (response.data.data.length) {
        dispatch(setRides(response.data.data));
        dispatch(setLoading(false));
        return true;
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error(error.message);
      return false;
    }
  };
}
