

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

function CoverLayout({ color, header, title, description, image, top, children }) {
  return (
    <PageLayout background="white">
  <Grid
    container
    justifyContent="center"
    sx={{
      minHeight: "75vh",
      margin: 0,
      gap:2
    }}
  >
    <Grid
  item
  xs={12}
  md={5}

  order={{ xs: 0, md: 0 }}
  sx={{
    overflow: "hidden",
    borderBottomLeftRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
  }}
>
  <SoftBox
    height={{ xs: "30vh", md: "100%" }}
    right={{ md: "2rem", xl: "12rem" }}
    mt={10}
    sx={{
      backgroundImage: `url(${image})`,
      backgroundSize: "contain", // Adjust to 'contain' to fit the entire image
      backgroundPosition: "center", // Center the image
      backgroundRepeat: "no-repeat", // Prevent image repeat
    }}
  />
</Grid>

    <Grid item xs={11} sm={8} md={5} xl={3} order={{ xs: 1, md: 1 }}>
      <SoftBox mt={{ xs: 2, md: top }}>
        <SoftBox pt={3} px={3}>
          {!header ? (
            <>
              <SoftBox mb={1}>
                <SoftTypography variant="h3" fontWeight="bold" color={color} textGradient>
                  {title}
                </SoftTypography>
              </SoftBox>
              <SoftTypography variant="body2" fontWeight="regular" color="text">
                {description}
              </SoftTypography>
            </>
          ) : (
            header
          )}
        </SoftBox>
        <SoftBox p={3}>{children}</SoftBox>
      </SoftBox>
    </Grid>
  </Grid>
  {/* <Footer /> */}
</PageLayout>

  );
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  color: "info",
  top: 20,
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
