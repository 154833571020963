import { toast } from "react-toast";
import { apiConnector } from "../Connector";
import { setUser, setLoading, setUserDetails, deleteUserSuccess } from "../../slices/UserSlice";

import { UserEndpoint } from "../APIS";

const { GET_ALL_USER, GET_USER_DETAILS, DELETE_USER } = UserEndpoint;

export function getUsers() {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConnector("GET", GET_ALL_USER);
      if (response.data.data) {
        dispatch(setUser(response.data.data));
        dispatch(setLoading(false));
        return true;
      }
    } catch (error) {
      toast.error(error.message);
      return false;
    }
  };
}

export function getUserDetails(data) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConnector("POST", GET_USER_DETAILS, data);
      if (response.data.data) {
        dispatch(setUserDetails(response.data.data));
        dispatch(setLoading(false));
        return true;
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error(error.message);
      return false;
    }
  };
}

export function deleteUser(data) {
  return async (dispatch) => {
    try {
      const response = await apiConnector("POST", DELETE_USER, data);
      if (response.data.data) {
        dispatch(deleteUserSuccess(response.data.data));
        return true;
      }
    } catch (error) {
      console.log("error: ", error);
      return false;
    }
  };
}
