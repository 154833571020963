import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    rides: [],
    // ridesDetails: [],
    loading: false,
}


const rideSlice = createSlice({
    name: "rides",
    initialState: initialState,
    reducers: {
        setRides(state, value) {
            state.rides = value.payload
        },
        // setRideDetails(state, value) {
        //     state.ridesDetails = value.payload
        // },
        setLoading(state, value) {
            state.loading = value.payload
        },
    }
});

export const { setRides, setLoading } = rideSlice.actions;
export default rideSlice.reducer;