import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import PropTypes from "prop-types";
const DEFAULT_AVATAR = 'https://avatar.iran.liara.run/public/29';

// Author component for rendering author details
function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image?`${image}`:DEFAULT_AVATAR} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

Author.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

const authorsTableData = {
  columns: [
    { name: "User", align: "left" },
    { name: "Action", align: "center" },
  ],
  rows: [], // Will be dynamically populated in the Tables component
};

export { Author ,authorsTableData};
